import {
  FormEvent, useState,
} from 'react';
import { useTranslation } from 'next-i18next';

import { useBjForm } from 'src/hooks';
import {
  Input, Label, Textarea,
} from '@components/common/Forms';
import {
  fetcher, JobQuestion,
} from 'src/utils';
import { FooterButtons } from '@components/templates/job/after-apply/FooterButtons';
import { ApiReplyMiniInterview } from '@type/old-api-types';
import { bjToast } from '@components/common';
import { isReplyMiniInterviewError } from '../../../../type-predicates/old-api-predicates';

interface InterviewQuestionsProps {
  jobQuestion: JobQuestion[];
  locale: Locale;
  onSaveSuccess: () => void,
  jobSlug: string,
}

export const InterviewQuestionsForm = (props: InterviewQuestionsProps) => {
  const {
    jobQuestion, locale, onSaveSuccess, jobSlug,
  } = props;

  const { t } = useTranslation('common');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    record, checkFormValidation,
  } = useBjForm({});

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = checkFormValidation(event);
    if (!formData) {
      return;
    }
    setIsLoading(true);
    fetcher<ApiReplyMiniInterview>(`/v1/job/${jobSlug}/late-answer`, locale, {
      payload: {
        answers: Object.values(formData),
      } as ApiSchema<'LateApplication'>,
      method: 'POST',
      stringifyPayload: true,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (isReplyMiniInterviewError(response)) {
        bjToast.error(response.children.answers.children[0].children.reply.errors[0].message);
      } else {
        onSaveSuccess();
      }
    })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };


  // Render the form
  // **************************
  return (
    <div>
      <p className="pb-6 text-lg">
        {t('apply.wizard.mini_interview.subtitle')}
      </p>
      <form noValidate onSubmit={onSubmit}>
        {
          jobQuestion.map((item, index) => (
            <div key={item.id} className="mb-8">
              <Label
                htmlFor={`question${index}`}
                description={`#${index + 1}. ${item.question}`}
                descriptionClass="text-md font-bold"
                required
              >
                <Textarea
                  minLength={3}
                  required
                  {...record(`[${index}].reply`)}
                  placeholder={t('global.placeholder.answer')}
                />
                <Input type="hidden" {...record(`[${index}].visible`, { convertEmptyValue: 'toBoolean' })} value="" />
                <Input type="hidden" {...record(`[${index}].question.id`)} value={item.id} />
                <Input type="hidden" {...record(`[${index}].question.text`)} value={item.question} />
              </Label>
            </div>
          ))
        }
        <FooterButtons onSkip={onSaveSuccess} submitType="submit" isLoading={isLoading} />
      </form>
    </div>
  );
};

// Remove job from favorites. *********************************************************************
// pathname: /v1/job/favorite
// method:   DELETE
// ************************************************************************************************
import { useSwrMutationApi } from '@hooks/useSwrApi';

/**
 * @description SWR mutation: DELETE remove job from favorites. Response status ok: 204.
 * @param jobSlug - string. The job slug.
 * @param locale - string. The locale to use for the request. Default: 'ro'.
 * @param successCallback - function. The callback to call on success.
 * @param successToastText - string. The text to show in the success toast.
 */
export const useSwrJobRemoveFromFavorites = (jobSlug: string, locale: string, successCallback?: () => void, successToastText?: string) => {
  // SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<object, undefined>({
    url: `/v1/job/${jobSlug}/favorite`,
    locale,
    fetcherConfig: {
      method: 'DELETE',
    },
    apiOptions: {
      onSuccess: () => {
        // Callback
        if (successCallback) successCallback();
      },
    },
    toastsOptions: {
      showSuccessToast: true,
      successToastText,
      showErrorToast: true,
    },
  });

  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};

// Job user detail  *******************************************************************************
// pathname: /v1/job-operation/{jobSlug}/user-detail
// ************************************************************************************************
import { ApiJobUserDetail, ApiJobUserDetailOk } from '@type/v1-api-types';
import { useSwrApi } from '@hooks/useSwrApi';

/**
 * @description SWR: fetch job detail for current user.
 * @param jobSlug - string. The job slug.
 * @param shouldFetch - boolean. Whether to start fetch job user details (eg: fetch only for logged-in users). Default true.
 * @param locale - string. The locale to use for the request. Default: 'ro'.
 * @param serverSideData - ApiJobUserDetail. It holds the data retrieved from the server-side request. Optional.
 *
 * @example
 * const { data: jobDetail, isLoading: isLoadingJobDetails } = useSwrJobUserDetail(slug, true, 'ro');
 */
export const useSwrJobUserDetail = (jobSlug: string, shouldFetch: boolean = true, locale: string = 'ro', serverSideData?: ApiJobUserDetail) => {
  // SWR: fetch data
  // ********************************************
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiJobUserDetailOk>({
    url: `/v1/job-operation/${jobSlug}/user-detail`,
    shouldFetch,
    locale,
    serverSideData,
    apiOptions: {
      dedupingInterval: 1000 * 60, // 1 minute
    },
  });

  // Return data
  // ********************************************
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  };
};


// Get skill form a job ******************************************************************
// pathname: v2/job-operation/job/{jobSlug}/skills/suggested
// ******************************************************
import {
  ApiEscoSkillOk,
  useSwrApi,
} from '@hooks/useSwrApi';


/**
 * @description SWR mutation: We use this SWR for handling the current skills of a job. If the job does not have any skills setup
 * then we fetch some suggested ones and allow the user to complete them using an autocomplete
 * @param slug - string. The slug of the current job
 * @param locale - string. The locale to use for the request. Default: 'ro'.
 */


export const useSwrGetJobSkills = (slug: string, locale = 'ro') => {
  // Get data from API
  // ********************************************
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiEscoSkillOk>({
    url: `/v2/job-operation/job/${slug}/skills/suggested`,
    locale,
    apiOptions: {
      // 1 minute
      dedupingInterval: 60 * 1000,
      keepPreviousData: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  };
};

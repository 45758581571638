import { useTranslation } from 'next-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from '@components/common';
import { JobQuestion } from '@type/v1-api-types';
import { InterviewQuestionsForm } from '@components/templates/forms/interview/InterviewQuestions';

// Interface
// ******************************************
interface InterViewQuestionsModalProps {
  jobQuestion: JobQuestion[];
  locale: Locale;
  onSaveSuccess: () => void,
  jobSlug: string,
}

/**
 * @description Bulk apply modal component.
 *
 * - **jobList** - ApiSchema<'JobItem'>[] - A list with similar jobs for bulk apply.
 * - **locale** - Locale - The current app locale.
 */
export const InterViewQuestionsModal = NiceModal.create((props: InterViewQuestionsModalProps) => {
  // Destructure props
  const {
    jobQuestion, locale, onSaveSuccess, jobSlug,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Use a hook to manage the modal state
  const modal = useModal();

  // Close modal
  const closeModal = () => {
    void modal.hide();
  };

  const successCallback = () => {
    closeModal();
    onSaveSuccess();
  };

  // Render component
  // *****************************************************
  return (
    <Modal
      isOpen={modal.visible}
      onClose={
        /* Pass an empty function because we don't want to close the modal on backdrop click or on ESC key. */
        () => {}
      }
      size="md"
    >
      <Modal.Header content={t('apply.wizard.mini_interview.title')} onClose={closeModal} />
      <Modal.Body className="bg-surface-100">
        <InterviewQuestionsForm jobQuestion={jobQuestion} jobSlug={jobSlug} locale={locale} onSaveSuccess={successCallback} />
      </Modal.Body>
    </Modal>
  );
});

// IMPORTS ****************************************************************************************
// ************************************************************************************************
import {
  ApiJobBySlug, ApiJobBySlugOk,
  ApiSimilarJobs,
  ApiSimilarJobsOk,
  ApiReplyMiniInterview,
  APIReplyMiniInterviewError,
  ApiReplyMiniInterviewOk,
} from 'src/types/old-api-types';


// Job By Slug ************************************************************************************
// pathname: /job/{jobSlug}
// ************************************************************************************************
export const isJobBySlugOk = (res: ApiJobBySlug | void): res is ApiJobBySlugOk => res !== undefined && res !== null
  && (res as ApiJobBySlugOk).id > 0;


// Similar Jobs ************************************************************************************
// pathname: /jobs/{id}/similar
// ************************************************************************************************
export const isSimilarJobsOk = (res: ApiSimilarJobs | void): res is ApiSimilarJobsOk => res !== undefined && res !== null
  && (res as ApiSimilarJobsOk).length >= 0;

// Job Reply Mini Interview ************************************************************************************
// pathname: /job/{id}/late-answer
// ************************************************************************************************
export const isReplyMiniInterviewOk = (res: ApiReplyMiniInterview | void): res is ApiReplyMiniInterviewOk => res !== undefined && res !== null
  && (res as ApiReplyMiniInterviewOk)?.answers?.length > 0;
export const isReplyMiniInterviewError = (res: ApiReplyMiniInterview | void): res is APIReplyMiniInterviewError => res !== undefined && res !== null
  && (res as APIReplyMiniInterviewError)?.children?.answers?.children?.length > 0;

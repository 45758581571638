import { createContext } from 'react';
import { JobListType } from '@type/job-params';

// Context interface
export interface JobTrackingContextInterface {
  requestId?: string,
  listType?: JobListType,
  searchId?: string,
}

/**
 * @description Context for tracking job actions. Used to store the request ID and other tracking params.
 *
 * - **requestId**: string - The tracking request ID.
 * - **listType**: JobListType - The list type (search list, similar list, favorite list, etc.). The JobListType enum contains all the available values.
 * - **searchId**: number - The saved search ID.
 */
export const JobTrackingContext = createContext<JobTrackingContextInterface>({
  requestId: undefined,
  listType: undefined,
  searchId: undefined,
});

// Apply to job ***********************************************************************************
// pathname: /v1/job/{slug}/apply
// method:   POST
// ************************************************************************************************
import {
  ApiJobApplyOk, ApiJobApplyPayload, GenericV1ErrorsArray, useSwrMutationApi,
} from '@hooks/useSwrApi';
import { SwrToastsProps } from '@type/swr';

// Interface
// ********************************************
interface SwrTopJobApplyProps {
  slug: string,
  workplaceSlug: string,
  locale?: string,
  successCallback?: () => void,
  errorCallback?: (errors: GenericV1ErrorsArray) => void,
  toastsOptions?: SwrToastsProps,
}

/**
 * @description SWR mutation: POST apply to job. Response status ok: 200.
 *
 * * **slug** - string. The job slug.
 * * **locale** - string. The locale to use for the request. Default: 'ro'.
 * * **successCallback** - function, optional. The callback to call on success.
 * * **errorCallback**: function, optional. The callback to call on fail.
 * * **toastsOptions**: SWR toasts options, optional.
 */
export const useSwrTopJobApply = (props: SwrTopJobApplyProps) => {
  // Props destructuring
  // ********************************************
  const {
    slug,
    workplaceSlug,
    locale = 'ro',
    successCallback,
    errorCallback,
    toastsOptions,
  } = props;

  // SWR mutation
  // ********************************************
  return useSwrMutationApi<ApiJobApplyOk, ApiJobApplyPayload>({
    url: `/v1/workplace/${workplaceSlug}/jobs/${slug}/apply`,
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: () => {
        // Callback
        if (successCallback) successCallback();
      },
      onError: (errors) => {
        // Callback
        if (errorCallback) errorCallback(errors);
      },
    },
    toastsOptions: {
      showSuccessToast: true,
      showErrorToast: true,
      ...toastsOptions,
    },
  });
};

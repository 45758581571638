// Jobs Bulk apply  *******************************************************************************
// pathname: /v2/job-operation/bulk-apply
// ************************************************************************************************
import { ApiBulkApplyOk, useSwrMutationApi } from 'src/hooks/useSwrApi';


// SWR: Bulk apply for similar jobs
// ********************************************
export const useSwrJobBulkApply = (locale = 'ro', successCallback?: () => void, successToastText?:string) => {
  // SWR wrapper
  const {
    data, error, trigger, reset, isMutating,
  } = useSwrMutationApi<ApiBulkApplyOk, object>({
    url: '/v2/job-operation/bulk-apply',
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: () => {
        if (successCallback) successCallback();
      },
    },
    toastsOptions: {
      showSuccessToast: true,
      successToastText,
      showErrorToast: true,
    },
  });

  // return data
  return {
    data,
    error,
    trigger,
    reset,
    isMutating,
  };
};

// Job detail  ************************************************************************************
// pathname: /v1/job-operation/{jobSlug}/detail
// ************************************************************************************************
import { ApiJobDetail, ApiJobDetailOk, ApiJobDetailQueryParams } from 'src/types/v1-api-types';
import { useSwrApi } from 'src/hooks/useSwrApi';
import { buildSearchParams } from 'src/utils';

/**
 * @description SWR: fetch job detail.
 * @param jobSlug - string. The job slug.
 * @param locale - string. The locale to use for the request. Default: 'ro'.
 * @param queryParams - ApiJobDetailQueryParams. Additional query params. Optional.
 * @param serverSideData - ApiJobDetail. It holds the data retrieved from the server-side request. Optional.
 *
 * @example
 * const { data: jobDetail, isLoading: isLoadingJobDetails } = useSwrJobDetail(jobSlug, 'ro', { includeSeoData: 1 });
 */
export const useSwrJobDetail = (jobSlug: string, locale: string = 'ro', queryParams: ApiJobDetailQueryParams = {}, serverSideData?: ApiJobDetail) => {
  // Build query params; if no params are passed, it will return an empty string
  const urlParams = buildSearchParams<ApiJobDetailQueryParams>(queryParams);

  // SWR: fetch data
  // ********************************************
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiJobDetailOk>({
    url: `/v1/job-operation/${jobSlug}/detail`,
    urlParams,
    locale,
    serverSideData,
    apiOptions: {
      dedupingInterval: 1000 * 60 * 5, // 5 minutes
    },
  });

  // Return data
  // ********************************************
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  };
};

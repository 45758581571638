import { Button } from '@components/common';
import { useTranslation } from 'next-i18next';

interface IProps {
  onSkip: () => void;
  submitType: 'button' | 'submit',
  onSubmit?: () => void;
  isLoading?: boolean
}

/**
 * @description Renders 2 buttons
 * * - one for submitting the form using `onSubmit` method
 * * - one for skip the current step
 * @param onSkip - action when user skips
 * @param submitType - button | submit
 * @param onSubmit - calls when submitting form
 * @param isLoading - state of the submit button after the user click on it
 */
export const FooterButtons = ({
  onSkip, submitType = 'submit', onSubmit, isLoading = false,
}: IProps) => {
  const { t } = useTranslation('common');

  const submitProps = onSubmit ? { onClick: onSubmit } : {};

  return (
    <div className="flex flex-col sm:items-start md:items-center">
      <Button
        type={submitType}
        styling="solid"
        color="secondary"
        size="md"
        className="mb-2 w-full rounded-full md:mb-6 md:w-auto"
        isLoading={isLoading}
        disabled={isLoading}
        {...submitProps}
      >
        {t('global.action.save')}
      </Button>

      <Button
        size="sm"
        onClick={() => onSkip()}
        styling="text"
        color="primary"
        className="ml-0 block justify-start md:ml-4"
      >
        {t('onboarding.step.skip')}
        <svg width="7" height="13" viewBox="0 0 7 13" fill="none" className="ml-3" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.65625 0.816406L0.109375 1.33594C0 1.47266 0 1.69141 0.109375 1.80078L5.05859 6.75L0.109375 11.7266C0 11.8359 0 12.0547 0.109375 12.1914L0.65625 12.7109C0.792969 12.8477 0.984375 12.8477 1.12109 12.7109L6.86328 6.99609C6.97266 6.85938 6.97266 6.66797 6.86328 6.53125L1.12109 0.816406C0.984375 0.679688 0.792969 0.679688 0.65625 0.816406Z" fill="#224D89" />
        </svg>
      </Button>
    </div>
  );
};

// Add a specific skill to a job  ******************************************************************
// pathname: v2/job-operation/job/{jobSlug}/skills/suggested/add
// ************************************************************************************************
import { ApiBulkApplyOk, useSwrMutationApi } from 'src/hooks/useSwrApi';


/**
 * @description SWR mutation: We use this SWR for handling the current skills of a job. When selecting one skill
 * from the autocomplete, or click on the suggested one from (useSWRGetJobSkills) then we call this SWR.
 * @param jobSlug - string. The slug of the current job
 * @param locale - string. The locale to use for the request. Default: 'ro'.
 */


// ********************************************
export const useSwrAddSkillToJob = (jobSlug:string, locale = 'ro') => {
  // SWR wrapper
  const {
    data, error, trigger, reset,
  } = useSwrMutationApi<ApiBulkApplyOk, object>({
    url: `/v2/job-operation/job/${jobSlug}/skills/add`,
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
  });

  // return data
  return {
    data,
    error,
    trigger,
    reset,
  };
};

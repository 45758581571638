// Breadcrumb for job search ****************************
// pathname: /v1/jobs/breadcrumb
// ******************************************************
import {
  ApiBreadcrumbsOk,
  useSwrApi,
} from '@hooks/useSwrApi';


// Interface
// ********************************************
interface SwrJobListBreadcrumbsProps {
  locale?: string;
  shouldFetch?: boolean
  urlParams?: string;
  serverSideData?: ApiBreadcrumbsOk,
}

/**
 * @description SWR: Fetch breadcrumbs data for job listings.
 * * **locale** - string. The locale to use for the request. Default: 'ro'.
 * * **shouldFetch**: boolean. A boolean indicating whether the API request should be made. Default: true.
 * * **urlParams** - string. The URL parameters to include in the API request.
 * * **serverSideData** - ApiBreadcrumbsOk. Breadcrumbs data from the server to be used initially.
 */


export const useSwrJobListBreadcrumbs = (props: SwrJobListBreadcrumbsProps) => {
  // Destructure props
  const {
    locale = 'ro', shouldFetch = true, urlParams, serverSideData,
  } = props;

  // Get data from API
  // ********************************************
  const {
    data, error, isLoading, isValidating,
  } = useSwrApi<ApiBreadcrumbsOk>({
    url: '/v1/jobs/breadcrumb',
    locale,
    shouldFetch,
    urlParams,
    serverSideData,
    apiOptions: {
      // 1 minute
      dedupingInterval: 60 * 1000,
      revalidateOnFocus: false,
      keepPreviousData: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    isLoading,
    isValidating,
  };
};

import { useTranslation } from 'next-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from '@components/common';
import { PersonalDataForm } from '@components/templates/profile/personal-data/PersonalDataForm';

// Interface
// ******************************************
interface PersonalDataModalProps {
  locale: Locale;
  onUpdateSuccess?: () => void;
}

/**
 * @description Personal data modal component.
 *
 * - **locale** -Locale - The current app locale.
 * - **onUpdateSuccess** - function. A callback function to execute after the personal data is updated successfully.
 */
export const PersonalDataModal = NiceModal.create((props: PersonalDataModalProps) => {
  // Destructure props
  const {
    locale,
    onUpdateSuccess,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Use a hook to manage the modal state
  const modal = useModal();

  // Close modal
  const closeModal = () => {
    void modal.hide();
  };

  // Render component
  // *****************************************************
  return (
    <Modal
      isOpen={modal.visible}
      onClose={
        /* Pass an empty function because we don't want to close the modal on backdrop click or on ESC key. */
        () => {}
      }
      /* Note that `overflow-visible` is mandatory. Otherwise, the datepicker will be clipped.  */
      className="!overflow-visible"
    >
      <Modal.Header content={t('post.application.no.personal.details.question')} onClose={closeModal} />
      <Modal.Body className="bg-surface-50">
        <PersonalDataForm
          isModalLayout
          locale={locale}
          showSocialProfiles={false}
          showPrivacyLevel={false}
          onRequestUpdatedData={() => {
            if (onUpdateSuccess) {
              onUpdateSuccess();
            }
            closeModal();
          }}
        />
      </Modal.Body>
    </Modal>
  );
});

// Job suggested salary ***************************************************************************
// pathname: /v1/job-operation/suggested-salary
// ************************************************************************************************
import { useSwrApi } from '@hooks/useSwrApi';
import { ApiRequestBody } from '@type/api/apiUtilityFn';

/** Props */
interface UseSwrJobSuggestedSalaryProps {
  locale: Locale,
  payload: Partial<ApiRequestBody<'/v1/job-operation/suggested-salary', 'post'>>,
  shouldFetch?: boolean,
  successCallback?: (data: ApiSchema<'SalarySuggestion'>) => void,
  onDataChanged?: (data?: ApiSchema<'SalarySuggestion'>) => void,
}

/**
 * @description SWR: fetch job suggested salary.
 * - `locale` - the current locale
 * - `payload` - the payload
 * - `shouldFetch` - condition for fetching the data. Default true.
 * - `successCallback` - success callback; works only on successful fetch
 * - `onDataChanged` - data changed callback; works on every data change (from cache or fetch)
 */
export const useSwrJobSuggestedSalary = (props: UseSwrJobSuggestedSalaryProps) => {
  const {
    locale,
    payload,
    shouldFetch = true,
    successCallback,
    onDataChanged,
  } = props;

  return useSwrApi<ApiSchema<'SalarySuggestion'>>({
    url: '/v1/job-operation/suggested-salary',
    locale,
    shouldFetch,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
      payload,
    },
    apiOptions: {
      revalidateOnFocus: false,
      revalidateOnMount: false,
      keepPreviousData: true,
      onSuccess: (response) => {
        if (successCallback) successCallback(response);
      },
    },
    onDataChange: (currentData) => {
      if (onDataChanged) onDataChanged(currentData);
    },
  });
};
